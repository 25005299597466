'use client'

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash'
import Link from 'next/link'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useSnackbar } from 'notistack'
import type LocalStorageUserInfo from '@/types/localStorageUserInfo'
import { getLongFullName } from '@/lib/utils'
import type { User } from '@/types/entities'
import { useFocusNextElement, useIsTouchScreenDevice } from '@/app/hooks'
import * as Sentry from '@sentry/nextjs'

export default function LoginPage(): React.ReactElement {
  const searchParams = useSearchParams()

  const [user, setUser] = React.useState<User | null>(null)
  const [email, setEmail] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(!!searchParams.get('token'))
  const [isLoginSuccessful, setIsLoginSuccessful] = React.useState<boolean>(false)
  const [localStorageUserInfo, setLocalStorageUserInfo] = React.useState<LocalStorageUserInfo | null>(null)

  const avatarRef = React.useRef<HTMLImageElement | null>(null)

  const isTouchScreenDevice = useIsTouchScreenDevice()
  const focusNextElement = useFocusNextElement()
  const { enqueueSnackbar } = useSnackbar()

  const login = React.useCallback(async (token: string): Promise<void> => {
    await (
      await import('@sentry/nextjs')
    ).startSpan(
      {
        name: 'Login Success',
      },
      async () => {
        const { clientApiService } = await import('@/services/client-api-service')

        const { body, status } = await clientApiService.getCurrentUser.query({
          headers: {
            authorization: `Bearer ${token}`,
          },
        })

        if (status !== 200) {
          console.error(body.message)
          throw new Error(body.message)
        }

        setUser(body.data)

        const Sentry = await import('@sentry/nextjs')

        Sentry.setUser({
          id: body.data.id,
          username: body.data.email ?? '',
          email: body.data.email ?? '',
        })

        const { setCookie } = await import('@/app/server-actions/cookies')
        const { DateTime } = await import('luxon')

        await setCookie('auth-token', token, {
          path: '/',
          httpOnly: true,
          secure: true,
          sameSite: 'none',
          priority: 'medium',
          expires: DateTime.now().plus({ day: 7 }).toJSDate(),
        })

        if (process.env['NEXT_PUBLIC_PICTRIX_STAGE'] === 'prod') {
          const { sendGTMEvent } = await import('@next/third-parties/google')

          sendGTMEvent({
            event: 'login',
            user_id: body.data?.id,
          })
        }

        setIsLoginSuccessful(true)
      },
    )
  }, [])

  const handleLogin = async (): Promise<void> => {
    if (!email || !password) {
      const Snackbar = (await import('@/app/components/Snackbar')).default

      enqueueSnackbar(
        <Snackbar>
          <p>Please fill in all required fields</p>
        </Snackbar>,
        { variant: 'error' },
      )

      return
    }

    await Sentry.startSpan(
      {
        name: 'Login Start',
      },
      async () => {
        const axios = (await import('axios')).default

        try {
          setIsLoggingIn(true)

          const response = await axios.get<{ success: true; data: { 'auth-token': string } } | { success: false; message: string }>(
            `${process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL']}/auth/pictrix/authorize`,
            {
              params: {
                email,
                password,
                response_type: 'token',
                redirect_uri: `${process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL']}/auth/pictrix/callback`,
                client_id: 12345,
              },
            },
          )

          if (!response.data.success) {
            setIsLoggingIn(false)

            const Snackbar = (await import('@/app/components/Snackbar')).default

            enqueueSnackbar(
              <Snackbar>
                <p>{response.data.message ?? 'Invalid email/password combination'}</p>
              </Snackbar>,
              { variant: 'error' },
            )
          } else {
            await login(response.data.data['auth-token'])
          }
        } catch (error: unknown) {
          setIsLoggingIn(false)

          const Snackbar = (await import('@/app/components/Snackbar')).default

          if (axios.isAxiosError<{ success: false; message: string }>(error)) {
            enqueueSnackbar(
              <Snackbar>
                <p>{error.response?.data.message ?? 'Invalid email/password combination'}</p>
              </Snackbar>,
              { variant: 'error' },
            )
          } else if (error instanceof Error) {
            enqueueSnackbar(
              <Snackbar>
                <p>Maintenance underway. Please try again in a few minutes.</p>
              </Snackbar>,
              { variant: 'error' },
            )
            console.error(error.message)
          } else {
            enqueueSnackbar(
              <Snackbar>
                <p>Maintenance underway. Please try again in a few minutes.</p>
              </Snackbar>,
              { variant: 'error' },
            )
            console.error(error)
          }
        }
      },
    )
  }

  React.useEffect(() => {
    void (async () => {
      if (avatarRef?.current && user) {
        const { DateTime } = await import('luxon')

        avatarRef.current.src = `${process.env['NEXT_PUBLIC_PICTRIX_IMAGES_URL']}/${user.picture_url}?${DateTime.fromISO(user.updated_at).toUnixInteger()}`
      }
    })()
  }, [user])

  React.useEffect(() => {
    if (!searchParams.get('token')) {
      setLocalStorageUserInfo(JSON.parse(localStorage.getItem('userInfo') ?? '{}') as LocalStorageUserInfo)
      setEmail(localStorageUserInfo?.email ?? '')
    }
  }, [localStorageUserInfo?.email, searchParams])

  React.useEffect(() => {
    void (async () => {
      const token = searchParams.get('token')

      if (token) {
        await login(token)
      }
    })()
  }, [login, searchParams])

  React.useEffect(() => {
    if (isLoginSuccessful) {
      window.location.href = '/generate'
    }
  }, [isLoginSuccessful])

  const isSameUser = email === localStorageUserInfo?.email

  return (
    <div className="mx-auto my-12 flex flex-col gap-8">
      <div className="dark:shadow-shadow-dark border-border dark:border-border-dark mx-auto flex w-full max-w-xs flex-col gap-8 rounded-xl border p-8 shadow-xl sm:max-w-sm">
        <h1 className="text-center text-2xl font-bold">
          {isSameUser && localStorageUserInfo?.firstName ? `Welcome back, ${localStorageUserInfo?.firstName}!` : 'Sign in to your account'}
        </h1>
        {isSameUser && localStorageUserInfo?.pictureUrl && (
          <div className="flex justify-center">
            <Image
              ref={avatarRef}
              className="rounded-full"
              src={localStorageUserInfo?.pictureUrl}
              alt={getLongFullName(localStorageUserInfo?.firstName, localStorageUserInfo?.middleName, localStorageUserInfo?.lastName)}
              width={50}
              height={50}
            />
          </div>
        )}
        <form
          onSubmit={(event: React.FormEvent) => {
            event.preventDefault()

            void handleLogin()
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                <svg
                  className="text-disabled-text dark:text-secondary-text-dark size-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 16"
                >
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>
              </div>
              <input
                type="email"
                enterKeyHint="next"
                value={email}
                disabled={isLoggingIn}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value)
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                  if (isTouchScreenDevice && event.key === 'Enter') {
                    event.preventDefault()

                    focusNextElement()
                  }
                }}
                className="input w-full ps-10"
                placeholder="Email"
              />
            </div>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-1 start-0 flex items-center ps-2.5">
                <svg
                  className="text-disabled-text dark:text-secondary-text-dark size-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type={showPassword ? 'text' : 'password'}
                enterKeyHint="go"
                value={password}
                disabled={isLoggingIn}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value)
                }}
                className="input w-full pe-12 ps-10"
                placeholder="Password"
              />
              <div className="absolute inset-y-0 end-0 flex translate-y-px items-center pe-4">
                <button
                  type="button"
                  disabled={isLoggingIn}
                  aria-label="Toggle password visibility"
                  className="cursor-pointer transition-all disabled:opacity-50"
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="text-default text-secondary-text dark:text-secondary-text-dark size-5"
                  />
                </button>
              </div>
            </div>
            <div className="text-link-text dark:text-link-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover text-xs transition-colors">
              <Link href="/forgot-password">Forgot password?</Link>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {isLoggingIn ? (
              <button type="button" disabled className="button-large button-primary mt-4 inline-flex w-full">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="spinner-on-primary me-3 size-5"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Signing in...
              </button>
            ) : (
              <button type="submit" className="button-large button-primary mt-4 inline-flex w-full">
                Sign in
              </button>
            )}
            <div className="flex flex-nowrap items-center gap-4">
              <hr className="border-border dark:border-border-dark w-full" />
              <span className="text-secondary-text dark:text-secondary-text-dark text-xs">or</span>
              <hr className="border-border dark:border-border-dark w-full" />
            </div>
            <div className="mb-2 pb-2">
              <button
                type="button"
                disabled={isLoggingIn}
                className="button-large button-default-outline w-full text-lg"
                onClick={() => {
                  setIsLoggingIn(true)
                  window.location.href = `${process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL']}/auth/google/authorize?response_type=token&client_id=628908482779-0sfk5o8eldou543i115lfkrstqtr0ppe.apps.googleusercontent.com&redirect_uri=${process.env['NEXT_PUBLIC_PICTRIX_BACKEND_URL']}/auth/google/callback`
                }}
              >
                <FontAwesomeIcon
                  icon={faGoogle}
                  className="size-5.5 text-primary-text dark:text-primary-text-dark transition-all"
                  width={16}
                  height={16}
                />
                Sign in with Google
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="mx-auto">
        New to PicTrix?<span>&nbsp;</span>
        <Link
          href="/register"
          className="text-link-text dark:text-link-text-dark hover:text-link-text-hover dark:hover:text-link-text-dark-hover ml-2 transition-colors"
        >
          Create your account
        </Link>
      </div>
    </div>
  )
}
