import * as React from 'react'

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>(undefined)

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useIsServer = (): boolean => {
  const [isServer, setIsServer] = React.useState(true)

  React.useEffect(() => {
    setIsServer(false)
  }, [])

  return isServer
}

export const useIsClient = (): boolean => {
  const [isClient, setIsClient] = React.useState(false)

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}

export const useIsTouchScreenDevice = (): boolean => {
  const [isTouchScreenDevice, setIsTouchScreenDevice] = React.useState(false)

  React.useEffect(() => {
    try {
      document.createEvent('TouchEvent')
      setIsTouchScreenDevice(true)
    } catch (e) {
      setIsTouchScreenDevice(false)
    }
  }, [])

  return isTouchScreenDevice
}

export const useFocusNextElement = (): (() => void) => {
  return React.useCallback((): void => {
    const focusableSelectors = [
      'a:not([disabled])',
      'button:not([disabled])',
      'input:not([disabled]):not([type=hidden])',
      'select:not([disabled])',
      '[tabindex]:not([disabled]):not([tabindex="-1"])',
    ]

    const activeElement = document.activeElement as HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement

    if (activeElement?.form) {
      const focusable = Array.prototype.filter.call(
        activeElement.form.querySelectorAll(focusableSelectors.join(',')),
        (element: HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement) => {
          // must be visibile - but always including the current activeElement, regardless
          return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
        },
      )

      const index = focusable.indexOf(document.activeElement)

      if (index > -1) {
        const nextElement = (focusable[index + 1] ?? focusable[0]) as HTMLInputElement | HTMLTextAreaElement | HTMLButtonElement

        nextElement.focus()
      }
    }
  }, [])
}
